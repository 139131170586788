.full-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 200;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  align-items: center;
}

.text {
  width: 100%;
  position: relative;
}

.loadingtext {
  color: #616ded;
}

.loader {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 6px solid white;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  animation: anim1 3s linear infinite;
}

.loader::before {
  position: absolute;
  content: "";
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  border-top: 5px solid #fb785c;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fb785c;
  animation: anim1 1s linear infinite;
}

.contain {
  display: flex;
  justify-content: center;
  height: 200px;
  margin-top: 10vw;
  position: relative;
}

.load {
  animation: bounce 0.5s;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
    width: 200px;
    height: 100%;
  }

  100% {
    transform: translate3d(0, 200px, 0);
    width: 300px;
    height: 70px;
  }
}

@keyframes anim1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bubbles {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  margin-bottom: -0.5rem;
  margin-left: 2rem;
}

.bubbles > div {
  position: absolute;
}

.firstbubble {
  border: 3px solid #bdf3ff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: appearright 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes appearright {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }

  20% {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate(-50px, -50px);
    opacity: 0;
  }
}

.secondbubble {
  border: 3px solid #ffbdfd;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: appearleft 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes appearleft {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }

  20% {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate(-50px, 0px);
    opacity: 0;
  }
}

.thirdbubble {
  border: 3px solid #ffbdfd;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  animation: appearleft2 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes appearleft2 {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }

  20% {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate(-50px, -20px);
    opacity: 0;
  }
}

.fourthbubble {
  border: 3px solid #bdf3ff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: appearleft3 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes appearleft3 {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }

  20% {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate(50px, -50px);
    opacity: 0;
  }
}

.fifthbubble {
  border: 3px solid #ffbdfd;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: appearright5 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes appearright5 {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }

  20% {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate(50px, 0px);
    opacity: 0;
  }
}
