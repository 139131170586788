
html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}



#app {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper {
    width: 98%;
    height: calc(100vh - 130px);
}

.swiper.swiper-cards {
    overflow: hidden !important;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    padding: 1px;

}