.bouncing-balls-container {
    width: 80px;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  
  .ball {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fb785c;
    animation-name: bouncing-dots;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  .ball1 {
    animation-delay: 0.2s;
  }
  
  .ball2 {
    animation-delay: 0.4s;
  }
  
  .ball3 {
    animation-delay: 0.6s;
  }
  
  @keyframes bouncing-dots {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  