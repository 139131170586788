.draggable-list {
  width: 100%;
  margin-top: 5px;
  background-color: rgba(216, 215, 215, 0.3);
  padding: 20px;
  border-radius: 10px;
}

.task-container {
  background-color: inherit;
  border-radius: 5px;
  padding: 0px;
}

.task {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}
