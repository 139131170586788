.ask-anything-container {
    display: flex;
    flex-direction: column;
    gap: 9px;
    background-color: white;
    width: min-content;
    position: absolute;
    right: 0px;
    /* height: min-content; */
    bottom: 0px;
    border-radius: 30px 30px 0px 0px;
    border: 2px solid white;
    overflow-y: auto;
    padding: 24px;
    box-shadow: 0px 4px 115px rgba(0, 0, 0, 0.2);
}

.chatbot-container-fullscreen {
    display: flex;
    flex-direction: column;
    gap: 9px;
    background-color: white;
    width: 96vw;
    position: absolute;
    right: 10px;
    /* height: 94vh; */
    bottom: 10px;
    border-radius: 30px 30px 0px 0px;
    border: 2px solid white;
    overflow-y: auto;
    padding: 24px;
    box-shadow: 0px 4px 115px rgba(0, 0, 0, 0.2);
    /* Enable vertical scrollbar */
}

/* Handle on hover */

.chatbot-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.chatbot-body-container {
    overflow: auto;
    gap: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}

.chat-bot-profile-pic {
    display: flex;
    padding: 0px;
    /* border-radius: 50%;
    background-color: #106EAC; */
    overflow: hidden;
    height: fit-content;
}

.each-message {
    display: flex;
    gap: 7px;
    margin-top: 10px;


}



.each-message-user {
    display: flex;
    gap: 7px;
    flex-direction: row-reverse;
    margin-top: 10px;
}

@media (max-width: 768px) {
    /* .chatbot-container-fullscreen {
        height: 80vh;
    } */

    .each-message, .each-message-user {
        gap: 5px;


    }
}

.chat-bot-message-text {
    margin: 0;
    font-size: 0.9rem;
    font-family: poppins;
}

.chat-btn-2 {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    /* background-color: #106EAC; */
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
    position: fixed;
    bottom: 209px;
    right: 31px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    z-index: 2
}

.skill-section-chat-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background-color: #106EAC; */
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
    position: fixed;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer
}

@media (max-width: 786px) {

    .skill-section-chat-btn{
        width: 50px !important;
        height: 50px;
        padding: 3px;
        bottom: 18px;
    }
    .chat-btn-2 {
        width: 50px !important;
        height: 50px;
        padding: 3px;
        right: 31px;
        bottom: 212px
    }

    .chatbot-container-fullscreen {
        gap: 4px;
        padding: 5px;
        /* Enable vertical scrollbar */
    }

    .chatbot-container {
        gap: 4px;
    }

    .chatbot-body-container {
        gap: 15px;
        padding: 5px;
        padding-bottom: 70px;
    }
}