/* Prevent unwanted zoom on iOS devices */
input, textarea, select {
  font-size: 16px !important; /* Prevents zoom on focus in iOS */
}

/* Fix for iOS momentum scrolling */
* {
  -webkit-overflow-scrolling: touch;
}

/* Prevent double-tap zoom */
* { 
  touch-action: manipulation;
}

/* Fix for position fixed elements */
.fixed-element {
  position: fixed;
  -webkit-transform: translateZ(0);
}

/* Your existing styles */
.makeStyles-modalPaper-12{
    width: calc(100% - 64px) !important;
}

#root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}